<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>营销管理</template>
      <template v-slot:secondMenu>优惠券管理</template>
    </breadcrumb-nav>
    <el-card>
      <el-button
        type="primary"
        icon="el-icon-plus"
        style="margin: 10px 0px"
        @click="addCoupon"
      >添加
      </el-button>
      <el-table style="width: 100%" border :data="list">
        <el-table-column type="index" label="序号" width="50px" align="center">
        </el-table-column>
        <el-table-column prop="title" label="标题">
        </el-table-column>
        <el-table-column label="类型" align="center">
          <template slot-scope="scope">{{ scope.row.type | typeLabel }}</template>
        </el-table-column>
        <el-table-column label="发放场景" align="center">
          <template slot-scope="scope">{{ scope.row.sence | senceLabel }}</template>
        </el-table-column>
        <el-table-column label="使用范围"  align="center">
          <template slot-scope="scope">{{ scope.row.scope | scopeLabel }}</template>
        </el-table-column>
        <el-table-column label="获得方式"  align="center">
          <template slot-scope="scope">{{ scope.row.obtainType | obtainTypeLabel }}</template>
        </el-table-column>
        <el-table-column prop="reduceAmount" label="抵扣金额" >
        </el-table-column>
        <el-table-column prop="minPoint" label="使用门槛">
        </el-table-column>
        <el-table-column prop="quota" label="发放限制">
        </el-table-column>
        <el-table-column label="时效类型"  align="center">
          <template slot-scope="scope">{{ scope.row.validType | validTypeLabel }}</template>
        </el-table-column>
        <el-table-column label="时效范围"  align="center">
          <template slot-scope="scope">{{ scope.row | validDateLabel }}</template>
        </el-table-column>
        <el-table-column label="状态"  align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-value=1 :inactive-value=2 @change="changeCouponStatus(scope.row)">
          </el-switch></template>
        </el-table-column>
        <el-table-column prop="prop" label="操作" width="100px">
          <template slot-scope="{row}">
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateCoupon(row)"
            >修改</el-button
            >
            <!--<el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="del(row)"
            >删除</el-button
            >-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5, 10]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { listCouponRequest, updateCouponStatusRequest } from '../../../network/coupon'

export default {
  name: 'CouponList',
  components: {
    BreadcrumbNav
  },
  filters: {
    typeLabel (value) {
      switch (value) {
        case 1:
          return '满减券'
        default:
          return '其他'
      }
    },
    senceLabel (value) {
      switch (value) {
        case 1:
          return '新人发放'
        case 2:
          return '大转盘中奖'
        case 3:
          return '拆红包中奖'
        default:
          return '其他'
      }
    },
    scopeLabel (value) {
      switch (value) {
        case 1:
          return '全场通用'
        default:
          return '其他'
      }
    },
    obtainTypeLabel (value) {
      switch (value) {
        case 1:
          return '系统发放'
        case 2:
          return '用户领取'
        default:
          return '其他'
      }
    },
    validTypeLabel (value) {
      switch (value) {
        case 1:
          return '绝对时效'
        case 2:
          return '相对时效'
        default:
          return '其他'
      }
    },
    validDateLabel (row) {
      if (row.validType === 2) {
        return '发放后' + row.validTime + '天内有效'
      } else {
        const date = new Date(row.startTime)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1 + '').padStart(2, '0')
        const day = (date.getDate() + '').padStart(2, '0')
        const endDate = new Date(row.endTime)
        const endYear = endDate.getFullYear()
        const endMonth = (endDate.getMonth() + 1 + '').padStart(2, '0')
        const endDay = (endDate.getDate() + '').padStart(2, '0')
        return `${year}-${month}-${day}` + '~' + `${endYear}-${endMonth}-${endDay}`
      }
    },
    statusLabel (value) {
      switch (value) {
        case 1:
          return '生效'
        case 2:
          return '失效'
        default:
          return '其他'
      }
    }
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: []
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getList()
  },
  methods: {
    getList () {
      listCouponRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    addCoupon () {
      this.$router.push({ path: '/addOrUpdateCoupon' })
    },
    updateCoupon (row) {
      this.$router.push({ path: '/addOrUpdateCoupon', query: { couponId: row.couponId } })
    },
    del (row) {
      console.log('del row:', row)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getList()
    },
    changeCouponStatus (row) {
      updateCouponStatusRequest({ couponId: row.couponId, status: row.status }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改状态失败', 'error')
        }
        this.alertMessage('修改状态成功', 'success')
        this.getList()
      })
    }
  }
}
</script>

<style scoped>

</style>
